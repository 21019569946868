import loadable from '@react-loadable/revised';
import SvgComet from 'assets/animator/commet.inline.svg';
import FooterLP from 'components/FooterLP';
import Layout from 'components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import useSearchParams, { getSearchParam } from 'hooks/useSearchParams';
import React, { useEffect, useRef } from 'react';
import useInView from 'react-cool-inview';
import { scrollInto } from 'utils/helper';

const Planet = loadable({
	loader: () => import('assets/jsx/Planet'),
	loading: () => <div />
});

const LazyLoadIndex = loadable({
	loader: () => import('components/pages/esg-compliant-supply-chain-financing/view/LazyLoadIndex'),
	loading: () => <div />
});

export default function EsgPage() {
	const { observe, unobserve, inView } = useInView({});
	const { location, searchLangParams } = useSearchParams();
	const { t } = useTranslation();
	const refWhoIsAnimator = useRef();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(inView) {
			unobserve();
		}
	}, [inView]);

	useEffect(() => {
		if(
			getSearchParam(location.search) === searchLangParams.section.contactForm ||
			getSearchParam(location.search) === searchLangParams.section.calculatorSection
		) {
			scrollInto(refWhoIsAnimator);
		}
	}, []);

	return (
		<Layout>
			<div>
				<div>
					<div className='layout relative'>
						<div style={{ marginRight: '-3.2rem' }} className='hidden sm:block absolute w-75/100 right-0 mt-50 2xl:w-full 2xl:-right-35/100 2xl:mt-0 top-0 -z-10'>
							<StaticImage src='../../../assets/esg/head-desktop.png' alt="" placeholder='none' />
						</div>

						<h1 className='sm:text-shadow pt-40 lg:pt-50 xl:pt-80 sm:w-58/100'>{t('pageEsg.esg.h1')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h1>

						<div className='w-full sm:w-50/100 2xl:h-160'>
							<p className='font-bold'>{t('pageEsg.esg.bold')}</p>
							<p>{t('pageEsg.esg.desc')}</p>

							<p className='mt-10 mb-05'>{t('pageEsg.esg.list.title')}</p>

							<ul className="ul-custom-triangle">
								<li className="text-10 leading-10">{t('pageEsg.esg.list.1')}</li>
								<li className="text-10 leading-10">{t('pageEsg.esg.list.2')}</li>
								<li className="text-10 leading-10">{t('pageEsg.esg.list.3')}</li>
								<li className="text-10 leading-10">{t('pageEsg.esg.list.4')}</li>
							</ul>
						</div>
					</div>

					<div className='relative w-full mt-10 sm:hidden'>
						<StaticImage src='../../../assets/esg/head-mobile.png' alt="" placeholder='none' />
					</div>

					<div className='hidden sm:flex items-center justify-center mt-20 ml-200'>
						<Planet className='w-140 h-100' />
						<div className='hidden 2xl:block 2xl:mt-150'>
							<SvgComet tw='w-60 h-60' />
						</div>
					</div>
				</div>
			</div>

			<div>
				{/* sm:h-350 xl:h-400 3xl:h-550 */}
				<div className='layout relative'>
					<div className='hidden sm:block w-53/100 absolute left-0 top-0 -z-10'>
						<StaticImage src='../../../assets/animator/images/phone-dashboard-desktop.png' alt="" placeholder='none' />
					</div>

					<div ref={refWhoIsAnimator} className='sm:text-right'>
						<h2 className='mb-24 mt-20 sm:mt-30'>{t('pageEsg.whatEsgIs.h2')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h2>
						<p ref={observe} className='w-full sm:w-50/100 sm:ml-auto'>
							{t('pageEsg.whatEsgIs.desc')}<span className='font-bold'>{t('pageEsg.whatEsgIs.bold')}</span>
							<div className='hidden sm:block w-85/100 ml-auto mt-20'>
								<StaticImage src='../../../assets/animator/images/astronaut-comet-desktop.png' alt="" />
							</div>
						</p>
					</div>

					<div className='sm:hidden w-full mt-15'>
						<StaticImage src='../../../assets/animator/images/phone-dashboard-mobile.png' alt="" />
					</div>
					{/*{inView && (*/}
					{/*)}*/}
				</div>
			</div>

			{/*<SeoLazy*/}
			{/*	isOpen={!inView}*/}
			{/*	items={[*/}
			{/*		{ id: 1, tag: 'h2', text: t('pageEsg.whatEsgIs.h2') },*/}
			{/*		{ id: 2, tag: 'p', text: t('pageEsg.whatEsgIs.desc') },*/}
			{/*		{ id: 3, tag: 'h2', text: t('pageEsg.financingInstitutions.h2') },*/}
			{/*		{ id: 4, tag: 'p', text: t('pageEsg.financingInstitutions.desc.1') },*/}
			{/*		{ id: 5, tag: 'p', text: t('pageEsg.financingInstitutions.desc.2') },*/}
			{/*		{ id: 6, tag: 'p', text: t('pageEsg.financingInstitutions.desc.3') },*/}
			{/*		{ id: 7, tag: 'h2', text: t('pageEsg.financingSuppliers.h2') },*/}
			{/*		{ id: 8, tag: 'p', text: t('pageEsg.financingSuppliers.desc.1') },*/}
			{/*		{ id: 9, tag: 'p', text: t('pageEsg.financingSuppliers.desc.2') },*/}
			{/*		{ id: 10, tag: 'p', text: t('pageEsg.financingSuppliers.desc.3') },*/}
			{/*		{ id: 11, tag: 'p', text: t('pageEsg.financingSuppliers.desc.4') },*/}
			{/*		{ id: 12, tag: 'p', text: t('pageEsg.financingSuppliers.desc.list.title') },*/}
			{/*		{ id: 13, tag: 'p', text: t('pageEsg.financingSuppliers.desc.list.1') },*/}
			{/*		{ id: 14, tag: 'p', text: t('pageEsg.financingSuppliers.desc.list.2') },*/}
			{/*		{ id: 15, tag: 'p', text: t('pageEsg.financingSuppliers.desc.list.3') },*/}
			{/*		{ id: 16, tag: 'p', text: t('pageEsg.financingSuppliers.desc.5') },*/}
			{/*		{ id: 17, tag: 'p', text: t('pageEsg.financingSuppliers.desc.6') },*/}
			{/*		{ id: 18, tag: 'h3', text: t('pageEsg.finteqMoney.main.h3') },*/}
			{/*		{ id: 19, tag: 'p', text: t('pageEsg.finteqMoney.main.desc.1') },*/}
			{/*		{ id: 20, tag: 'p', text: t('pageEsg.finteqMoney.main.desc.2') },*/}
			{/*		{ id: 21, tag: 'h3', text: t('pageEsg.finteqMoney.system.h3') },*/}
			{/*		{ id: 22, tag: 'p', text: t('pageEsg.finteqMoney.system.desc.1') },*/}
			{/*		{ id: 23, tag: 'p', text: t('pageEsg.finteqMoney.system.desc.2') },*/}
			{/*		{ id: 24, tag: 'h3', text: t('pageEsg.finteqMoney.risk.h3') },*/}
			{/*		{ id: 25, tag: 'p', text: t('pageEsg.finteqMoney.risk.desc') },*/}
			{/*		{ id: 26, tag: 'p', text: t('pageEsg.howToStart.h2') },*/}
			{/*		{ id: 27, tag: 'p', text: t('pageEsg.howToStart.list.1') },*/}
			{/*		{ id: 28, tag: 'p', text: t('pageEsg.howToStart.list.2') },*/}
			{/*		{ id: 29, tag: 'p', text: t('pageEsg.howToStart.list.3') },*/}
			{/*	]}*/}
			{/*/>*/}

			<LazyLoadIndex />
			<FooterLP />
		</Layout>
	);
}
